import cryptojs from 'crypto-js';
import PreLoginEndpoint from '../endpoints/authentication/PreLoginEndpoint';
import LoginEndpoint from '../endpoints/authentication/LoginEndpoint';
import UserRegistrationEndpoint from '../endpoints/authentication/UserRegistrationEndpoint';
import RefreshTokenEndpoint from '../endpoints/authentication/RefreshTokenEndpoint';
import UserActivePingEndpoint from '../endpoints/authentication/UserActivePingEndpoint';
import Confirm2FAVerificationCodeEndpoint from '../endpoints/authentication/2fa/Confirm2FAVerificationCodeEndpoint';

export type LoginSession = {
  displayName: string;
  roles: Array<string>;
  token: string;
  uid: string;
};

const SESSION_STORAGE_KEY = 'dragon.ua';
const PATH_STORAGE_KEY = 'dragon.__pr';

const AuthUtils = {
  Roles: {
    USER: 'u',
    LOCKED: 'l',
    RESTRICTED: 'r',
    TESTER: 't',
    ADMIN: 'a',
  },

  ensureLoggedIn(role: string | null | undefined = null): void {
    if (!this.isLoggedIn()) {
      window.localStorage.setItem(PATH_STORAGE_KEY, window.location.href);
      window.location.href = '/login';
    }

    if (!!role) {
      if (!this.currentUserHasRole(role)) {
        window.localStorage.setItem(PATH_STORAGE_KEY, window.location.href);
        window.location.href = '/login?error=role';
      }
    }
  },

  redirectToLoginPage(withRoleError: string) {
    window.localStorage.setItem(PATH_STORAGE_KEY, window.location.href);
    window.location.href = `/login${withRoleError ? '?error=role' : ''}`;
  },

  getRedirectAfterLogin(): string {
    const path = window.localStorage.getItem(PATH_STORAGE_KEY);
    window.localStorage.removeItem(PATH_STORAGE_KEY);
    return path || '/';
  },

  getLoginSession(): LoginSession | null {
    return JSON.parse(
      window.localStorage.getItem(SESSION_STORAGE_KEY) || 'null'
    );
  },

  setLoginSession(session: LoginSession | null | undefined): void {
    window.localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(session));
  },

  currentUserHasRole(role: string): boolean {
    const session = this.getLoginSession();
    if (!session) {
      return false;
    }

    return session.roles && session.roles.includes(role);
  },

  clearLoginSession(): void {
    window.localStorage.removeItem(SESSION_STORAGE_KEY);
    window.location.href = '/login';
  },

  isLoggedIn(): boolean {
    return !!this.getLoginSession();
  },

  updateDisplayName(displayName: string): void {
    const session = this.getLoginSession();
    if (session === null) {
      return;
    }
    session.displayName = displayName;
    this.setLoginSession(session);
  },

  async register(
    inviteCode: string,
    userid: string,
    password: string,
    emailAddress: string,
    displayName: string
  ): Promise<boolean | { success: boolean; error: string | null | undefined }> {
    const passwordHash = cryptojs.SHA256(password).toString();
    const result = await UserRegistrationEndpoint.call({
      inviteCode,
      userid,
      passwordHash,
      emailAddress,
      displayName,
    });
    if (!result.success || !result.session) {
      return {
        success: false,
        error: result.error,
      };
    }

    this.setLoginSession(result.session);
    return true;
  },

  async login(
    userid: string,
    password: string
  ): Promise<boolean | { code: string; message: string }> {
    const passwordHash = cryptojs.SHA256(password).toString();
    const preLoginResult = await PreLoginEndpoint.call({ userid });
    const loginResult = await LoginEndpoint.call({
      token: preLoginResult.token,
      passwordHash: cryptojs
        .SHA256(preLoginResult.salt + passwordHash)
        .toString(),
    });
    if (!loginResult.success && !!loginResult.error) {
      return loginResult.error;
    }

    if (loginResult.need_2fa) {
      window.localStorage.setItem(
        '__tfuid',
        (loginResult.session && loginResult.session.uid) || ''
      );
      window.location.href = '/2fa';
      return {
        code: '',
        message: 'Redirecting to two-factor verification...',
      };
    }

    loginResult.session && this.setLoginSession(loginResult.session);
    return true;
  },

  async check2FAVerificationCode(uid: string, code: string): Promise<boolean> {
    const result = await Confirm2FAVerificationCodeEndpoint.call({
      uid,
      code,
    });

    if (result.success) {
      this.setLoginSession(result.session);
    }

    return result.success;
  },

  async refreshToken(): Promise<boolean> {
    const refreshResult = await RefreshTokenEndpoint.call({});
    if (refreshResult.success) {
      this.setLoginSession(refreshResult.session);
    }

    return refreshResult.success;
  },

  logout(): void {
    this.setLoginSession(null);
  },

  getSignedPasswordHash(password: string, salt: string): string {
    const passwordHash = cryptojs.SHA256(password).toString();
    return cryptojs.SHA256(`${passwordHash}::${salt}`).toString();
  },

  sendHeartbeat() {
    if (!!this.getLoginSession()) {
      UserActivePingEndpoint.call({});
    }
  },
};

export default AuthUtils;
