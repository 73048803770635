type Props = {
  requireLogin: boolean;
  roles: Array<string>;
  children: any;
};

function Page(props: Props): JSX.Element | null {
  if (!props.requireLogin) {
    return props.children;
  }

  const loginSession = (global as any).AuthUtils.getLoginSession();
  if (!loginSession) {
    (global as any).AuthUtils.redirectToLoginPage();
    return null;
  }

  if (props.roles.length === 0) {
    return props.children;
  }

  if (
    props.roles.some((role) =>
      (global as any).AuthUtils.currentUserHasRole(role)
    )
  ) {
    return props.children;
  }

  (global as any).AuthUtils.redirectToLoginPage(true);
  return null;
}

Page.defaultProps = {
  requireLogin: false,
  roles: [],
};

export default Page;
