import * as React from 'react';

export default function LogOutButton(props: {
  onLogOut: () => void;
}): JSX.Element {
  return (
    <>
      <div id="logout-modal" data-uk-modal>
        <div className="uk-modal-dialog uk-modal-body">
          <h3 className="uk-modal-title">Log out?</h3>
          Are you sure you want to log out?
          <div style={{ textAlign: 'right', marginTop: '1rem' }}>
            <button
              className="uk-button uk-button-default uk-modal-close"
              onClick={() => {
                props.onLogOut();
              }}
              type="button"
            >
              Yes, log out
            </button>{' '}
            <button
              className="uk-button uk-button-default uk-modal-close"
              type="button"
            >
              No, stay logged in
            </button>
          </div>
        </div>
      </div>
      <button
        className="navbar-item uk-button"
        data-testid="logout-button"
        data-uk-toggle="target: #logout-modal"
        data-balloon="Log out"
        data-balloon-pos="left"
        style={{
          padding: '0 1rem',
        }}
      >
        <i
          className="fas fa-sign-out-alt"
          style={{
            fontSize: '1.5rem',
            position: 'relative',
            top: '0.2rem',
          }}
        />
      </button>
    </>
  );
}
