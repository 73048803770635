import * as React from 'react';
import { TaskRecurrenceType } from '../../../enums/TaskRecurrence';
import Numbers from '../../../utils/Numbers';

type Props = {
  verb: string;
  recurrenceType: TaskRecurrenceType;
  recurrenceSchedule: string | null | undefined;
  recurrenceIntervalValue: number | null | undefined;
};

export default function TaskRecurrenceLabel(props: Props): JSX.Element | null {
  switch (props.recurrenceType) {
    case 'day_interval':
      return (
        <>
          {props.verb} every {props.recurrenceIntervalValue} days
        </>
      );
    case 'month_interval':
      return (
        <>
          {props.verb} every {props.recurrenceIntervalValue} months
        </>
      );
    case 'day_of_week':
      if (!props.recurrenceSchedule) {
        return null;
      }

      const days = props.recurrenceSchedule.split(',').filter((d) => !!d);

      if (days.length === 0) {
        return null;
      }

      return (
        <>
          {props.verb} on {days.map((d) => `${d}s`).join(', ')}
        </>
      );
    case 'day_of_month':
      if (!props.recurrenceSchedule) {
        return null;
      }

      return (
        <>
          {props.verb} on the {props.recurrenceSchedule}
          {Numbers.ordinal_suffix_of(parseInt(props.recurrenceSchedule))} day of
          each month
        </>
      );
    default:
      return null;
  }
}

TaskRecurrenceLabel.defaultProps = {
  verb: 'Recurs',
};
