import * as React from 'react';
import classSet from 'react-classset';
import { Link, NavLink } from 'react-router-dom';
import DashboardListNavLink from '../dashboards/list/DashboardListNavLink';
import LogOutButton from './LogOutButton';
import TaskSearchInput from './TaskSearchInput';

import './Header.css';

import logo from '../../mimir2_logo_full.png';

type Props = {};

type State = {
  shouldShowNavMenu: boolean;
};

export default class Header extends React.Component<Props, State> {
  state = {
    shouldShowNavMenu: false,
  };

  _onToggleNavMenu = (e: React.SyntheticEvent<HTMLElement>): void => {
    e.preventDefault();
    this.setState((prevState) => ({
      shouldShowNavMenu: !prevState.shouldShowNavMenu,
    }));
  };

  render() {
    return (
      <nav className="navbar mimir-nav" aria-label="main navigation">
        <div className="navbar-brand">
          <Link to="/">
            <img className="logo" alt="Mimir" src={logo} />
          </Link>

          <a
            href="/"
            role="button"
            className={classSet({
              'navbar-burger': true,
              'is-active': this.state.shouldShowNavMenu,
            })}
            aria-label="menu"
            aria-expanded="false"
            onClick={this._onToggleNavMenu}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div
          className={classSet({
            'navbar-menu': true,
            'mimir-nav-menu': true,
            'is-active': this.state.shouldShowNavMenu,
          })}
        >
          <NavLink
            to="/tasks/new"
            className="navbar-item new-task-link"
            activeClassName="active"
          >
            <i
              className="fas fa-plus-circle"
              style={{ marginRight: '0.25rem' }}
            ></i>
            New task
          </NavLink>
          <NavLink
            to="/"
            className="navbar-item"
            activeClassName="active"
            exact={true}
          >
            Backlog
          </NavLink>
          <NavLink
            to="/kanban"
            className="navbar-item"
            activeClassName="active"
            exact={true}
          >
            Kanban Board
          </NavLink>
          <DashboardListNavLink />
          <NavLink
            to="/projects"
            className="navbar-item"
            activeClassName="active"
          >
            Projects
          </NavLink>

          <TaskSearchInput />

          <div className="navbar-end">
            <NavLink
              to="/users/me"
              className="navbar-item"
              activeClassName="active"
            >
              <i className="fas fa-cog" style={{ marginRight: '0.25rem' }}></i>
              {!!(global as any).AuthUtils.getLoginSession()
                ? (global as any).AuthUtils.getLoginSession().displayName
                : ''}
            </NavLink>
            <LogOutButton
              onLogOut={() => {
                (global as any).AuthUtils.logout();
                window.location.href = '/login';
              }}
            />
          </div>
        </div>
      </nav>
    );
  }
}
