import React from 'react';
import gql from 'graphql-tag';
import { Query, QueryResult } from 'react-apollo';
import { Card, ErrorCard, CardSection } from '../../common/cards';
import TaskSummaryList from '../display/TaskSummaryList';
import Spinner from '../../common/Spinner';
import TaskSortTypeSelector from '../display/TaskSortTypeSelector';
import { SortType } from '../display/TaskSummaryListSorter';
import LocalSettings from '../../../utils/LocalSettings';
import { TaskSummaryFragment } from '../display/TaskSummary';

const SORT_STORAGE_KEY = 'backlog_sort';

export const GET_PERSONAL_BACKLOG_QUERY = gql`
  query {
    me {
      owned_tasks {
        ...TaskSummary
      }
    }
  }
  ${TaskSummaryFragment}
`;

export const GET_PROJECT_BACKLOG_QUERY = gql`
  query getProjectTasks($projectID: ID!) {
    project(id: $projectID) {
      id
      name
      description
      members {
        id
        display_name
      }
      tasks {
        ...TaskSummary
      }
    }
  }
  ${TaskSummaryFragment}
`;

type Props = {
  onTaskClicked: (taskNumber: number) => void;
  backlogType: 'personal' | 'project';
  projectID?: string;
};

type State = {
  selectedSortType: SortType;
};

class PersonalTaskBacklogCard extends React.Component<Props, State> {
  state = {
    selectedSortType:
      (LocalSettings.get(SORT_STORAGE_KEY, 'urgency') as SortType) || 'urgency',
  };

  _onSortTypeSelected = (selectedSortType: SortType): void => {
    this.setState({
      selectedSortType,
    });

    LocalSettings.set(SORT_STORAGE_KEY, selectedSortType);
  };

  render() {
    const backlogType = this.props.backlogType;
    return (
      <Query
        query={
          backlogType === 'personal'
            ? GET_PERSONAL_BACKLOG_QUERY
            : GET_PROJECT_BACKLOG_QUERY
        }
        variables={{ projectID: this.props.projectID }}
      >
        {(result: QueryResult<any>) => {
          const { loading, error, data } = result;
          if (!!loading) {
            return (
              <Card>
                <Spinner />
              </Card>
            );
          }

          if (!!error) {
            console.log(error);
            (global as any).EM.logException(error, {
              component: 'PersonalTaskBacklogCard',
              graphQLQuery:
                backlogType === 'personal' ? 'owned_tasks' : 'getProjectTasks',
              extraData: {
                projectID: this.props.projectID,
              },
            });
            return (
              <ErrorCard>
                <h2>:(</h2>
                Oops, couldn't get the task backlog. Try again later?
              </ErrorCard>
            );
          }

          if (backlogType === 'personal' && data.me == null) {
            // Can't fetch the uer at all, so probably session isn't valid any more
            (global as any).AuthUtils.clearLoginSession();
          }

          let tasks =
            backlogType === 'personal'
              ? data.me.owned_tasks
              : data.project.tasks;
          if (tasks.length === 0) {
            return (
              <div
                style={{
                  color: '#666',
                  textAlign: 'center',
                }}
              >
                You have no tasks in your backlog.
              </div>
            );
          }

          if (backlogType === 'project') {
            tasks = tasks.filter(
              (task: any) =>
                !!task.project && task.project.id === this.props.projectID
            );
          }

          return (
            <Card>
              <CardSection>
                <div style={{ marginBottom: '0.75rem' }}>
                  <TaskSortTypeSelector
                    selectedSortType={this.state.selectedSortType}
                    onChange={this._onSortTypeSelected}
                  />
                </div>
              </CardSection>
              <CardSection>
                <TaskSummaryList
                  tasks={tasks.map((task: any) => ({
                    id: task.id,
                    taskNumber: task.task_number,
                    title: task.title,
                    isOpen: task.is_open,
                    tags: task.tags.split(',').filter((tag: string) => !!tag),
                    startDate: task.start_date,
                    targetDate: task.target_date,
                    createdDate: task.created_date,
                    priority: task.priority,
                    creatorName: !!task.creator
                      ? task.creator.display_name
                      : 'Unknown',
                    prerequisitesStatuses: task.prerequisites,
                    project: task.project,
                    owner: task.owner,
                    recurrenceType: task.recurrence_type,
                    recurrenceSchedule: task.recurrence_schedule,
                    recurrenceIntervalValue: task.recurrence_interval_value,
                  }))}
                  onTaskClicked={this.props.onTaskClicked}
                  shouldPaginate={true}
                  shouldSortTasks={true}
                  sortType={this.state.selectedSortType}
                />
              </CardSection>
            </Card>
          );
        }}
      </Query>
    );
  }
}

export default PersonalTaskBacklogCard;
