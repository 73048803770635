// @source_hash=10d7b4c8b53b9db9d9eab2780ea453d6
// This is an autogenerated file.
// To make changes, edit app/server/endpoints/appInfoEndpoint.js,
// then run `dragon gen-endpoint app/server/endpoints/appInfoEndpoint.js`.
// Generated on Fri Apr 10 2020 11:05:42 GMT+0800 (+08)

export interface AppInfoEndpointParams {};

export interface AppInfoEndpointResult {
  app_name: string;
  version: string;
  build: string;
  environment: string;
  min_client?: string | null;
  latest_client?: string | null;
};

export default class AppInfoEndpoint {
  static call(params: AppInfoEndpointParams): Promise<AppInfoEndpointResult> {
    return (global as any).API.call(
      { url: "/_app_info_", type: "get", params: [] },
      params,
    );
  }
}
