const TAG_CACHE_STORAGE_KEY = 'm_taglist';

export default {
  getTags(): Array<string> {
    let tags = [];
    try {
      tags =
        JSON.parse(
          window.localStorage.getItem(TAG_CACHE_STORAGE_KEY) || '[]'
        ) || [];
    } catch (e) {}
    return tags;
  },

  addTagToCache(tag: string): void {
    const tags = this.getTags();
    if (!tags.includes(tag)) {
      tags.push(tag);
    }

    this._saveTagCache(tags);
  },

  initialiseTagsCache(tags: Array<string>): void {
    this._saveTagCache(tags);
  },

  _saveTagCache(tags: Array<string>): void {
    window.localStorage.setItem(TAG_CACHE_STORAGE_KEY, JSON.stringify(tags));
  },
};
