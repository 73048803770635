// @ts-nocheck

const SETTINGS_STORAGE_KEY = 'mimir_prefs';

export default {
  get(key: string, defaultValue: string): string {
    const settingsData = this._getSettingsData();
    return settingsData[key] || defaultValue;
  },

  set(key: string, value: string) {
    const settingsData = this._getSettingsData();
    settingsData[key] = value;
    this._saveSettingsData(settingsData);
  },

  _getSettingsData(): {
    [key: string]: string;
  } {
    if (!this._cache) {
      this._cache = JSON.parse(
        window.localStorage.getItem(SETTINGS_STORAGE_KEY) || '{}'
      );
    }

    return this._cache;
  },

  _saveSettingsData(data: { [key: string]: string }): void {
    this._cache = data;
    window.localStorage.setItem(SETTINGS_STORAGE_KEY, JSON.stringify(data));
  },
};
