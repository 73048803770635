// @ts-nocheck

import React from 'react';
import Spinner from '../common/Spinner';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      isLoggingIn: false,
    };
  }

  componentDidMount() {
    if (!!this.userIDInput) {
      this.userIDInput.focus();
    }
  }

  onInputKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.onClickLogin();
    }
  };

  onClickLogin = () => {
    const userID = this.userIDInput.value;
    const password = this.passwordInput.value;
    if (!userID || !password) {
      this.setState({
        errorMessage: 'Type your username and password, then click Log in.',
      });
      return;
    }

    this.setState({
      isLoggingIn: true,
    });

    global.AuthUtils.login(userID, password)
      .then((result) => {
        if (result === true) {
          window.location = global.AuthUtils.getRedirectAfterLogin();
        } else {
          const errorMessage = result.message
            ? `${result.message} (${result.code || 'LOGIN_ERR'})`
            : 'Could not log you in. Try again later';
          this.setState({
            errorMessage,
            isLoggingIn: false,
          });
        }
      })
      .catch((error) => {
        const errorMessage = error.message
          ? `${error.message} (${error.code})`
          : 'Could not log you in. Try again later';
        this.setState({
          errorMessage,
          isLoggingIn: false,
        });
      });
  };

  _renderErrorMessage() {
    if (!this.state.errorMessage) {
      return null;
    }

    return (
      <div data-uk-alert className="uk-alert-danger" data-testid="login-error">
        {this.state.errorMessage}
      </div>
    );
  }

  render() {
    return (
      <div>
        <input
          className="uk-input"
          placeholder="User ID"
          ref={(input) => (this.userIDInput = input)}
          data-testid="uid-input"
        />

        <input
          className="uk-input uk-margin-small-top"
          placeholder="Password"
          type="password"
          ref={(input) => (this.passwordInput = input)}
          onKeyUp={this.onInputKeyUp}
          data-testid="pwd-input"
        />

        <button
          data-testid="login-button"
          className="uk-button uk-button-default uk-margin-small-top"
          onClick={this.onClickLogin}
          disabled={this.state.isLoggingIn}
        >
          {this.state.isLoggingIn ? (
            <>
              <span style={{ position: 'relative', top: '-0.15rem' }}>
                <Spinner display="inline" />
              </span>
              Logging in
            </>
          ) : (
            <>Log in</>
          )}
        </button>

        {this._renderErrorMessage()}
      </div>
    );
  }
}

export default LoginForm;
