// @source_hash=60db95f98ff1a8ec41bd33621e93f68c
// This is an autogenerated file.
// To make changes, edit app/server/endpoints/authentication/UserActivePingEndpoint.js,
// then run `dragon gen-endpoint app/server/endpoints/authentication/UserActivePingEndpoint.js`.
// Generated on Fri Apr 10 2020 11:05:42 GMT+0800 (+08)

export interface UserActivePingEndpointParams {};

export interface UserActivePingEndpointResult {
  success: boolean;
};

export default class UserActivePingEndpoint {
  static call(params: UserActivePingEndpointParams): Promise<UserActivePingEndpointResult> {
    return (global as any).API.call(
      { url: "/api/authentication/ping", type: "get", params: [] },
      params,
    );
  }
}
