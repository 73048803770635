// @source_hash=2bf9cdb118780f6bbe0b5b09fb4ac788
// This is an autogenerated file.
// To make changes, edit app/server/endpoints/authentication/PreLoginEndpoint.js,
// then run `dragon gen-endpoint app/server/endpoints/authentication/PreLoginEndpoint.js`.
// Generated on Fri Apr 10 2020 11:05:42 GMT+0800 (+08)

export interface PreLoginEndpointParams {
  userid: string;
};

export interface PreLoginEndpointResult {
  token: string;
  salt: string;
};

export default class PreLoginEndpoint {
  static call(params: PreLoginEndpointParams): Promise<PreLoginEndpointResult> {
    return (global as any).API.call(
      { url: "/api/auth/prelogin", type: "get", params: [] },
      params,
    );
  }
}
