import React from 'react';
import { withRouter } from 'react-router-dom';

import './TaskSearchInput.css';

type Props = {
  history: any; // from React Router
};

type State = {
  searchTerm: string;
};

class TaskSearchInput extends React.Component<Props, State> {
  state = {
    searchTerm: '',
  };

  _onSearchTermChanged = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    this.setState({
      searchTerm: e.currentTarget.value,
    });
  };

  _onSearchInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.keyCode === 13) {
      this._onSearch();
    }
  };

  _onSearch = (): void => {
    const { searchTerm } = this.state;
    const uri = `/tasks/search${
      !!searchTerm ? `?q=${encodeURIComponent(searchTerm)}` : ''
    }`;
    if (window.location.pathname === '/tasks/search') {
      window.location.href = uri;
    } else {
      this.props.history.push(uri);
    }
  };

  render() {
    return (
      <>
        <input
          className="uk-input task-search-input"
          type="text"
          placeholder="Search tasks"
          value={this.state.searchTerm}
          onChange={this._onSearchTermChanged}
          onKeyUp={this._onSearchInputKeyUp}
        />
        <button
          className="uk-button uk-button-default search-button"
          onClick={this._onSearch}
        >
          <i className="fas fa-search"></i>
        </button>
      </>
    );
  }
}

// @ts-ignore
export default withRouter(TaskSearchInput);
