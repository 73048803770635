import * as React from 'react';
import classSet from 'react-classset';
import moment from 'moment';

type Props = {
  startDate: number | null | undefined;
  targetDate: number | null | undefined;
  format: string;
  highlightIfOverdue: boolean;
};

export default function TaskScheduleLabel(props: Props): JSX.Element {
  const now = moment();

  if (!!props.startDate && !!props.targetDate) {
    const start = moment.unix(props.startDate);
    const target = moment.unix(props.targetDate);
    return (
      <span
        className={classSet({
          overdue: now.isAfter(target),
        })}
      >
        <strong>Due {target.fromNow()}</strong> (Planned{' '}
        {start.format(props.format)} to {target.format(props.format)}).
      </span>
    );
  }

  if (!!props.startDate) {
    const start = moment.unix(props.startDate);
    return (
      <span>
        <strong>Start {start.fromNow()}</strong> ({start.format(props.format)}).
      </span>
    );
  }

  if (!!props.targetDate) {
    const target = moment.unix(props.targetDate);
    return (
      <span
        className={classSet({
          overdue: now.isAfter(target),
        })}
      >
        <strong>Due {target.fromNow()}</strong> ({target.format(props.format)}).
      </span>
    );
  }

  return <span>No start date or target date.</span>;
}

TaskScheduleLabel.defaultProps = {
  format: 'll',
  highlightIfOverdue: true,
};
