import * as React from 'react';

type Props = {
  label: string;
};

type TagTheme = {
  backgroundColor?: string;
  icon?: string;
};

const SPECIAL_TAGS: {
  [key: string]: TagTheme;
} = {
  epic: {
    backgroundColor: '#FFF59D',
    icon: 'sun',
  },
  started: {
    backgroundColor: '#e6ee9c',
    icon: 'play',
  },
  bug: {
    backgroundColor: '#FFCDD2',
    icon: 'bug',
  },
  feature: {
    backgroundColor: '#e1f5fe',
    icon: 'lightbulb',
  },
  fixready: {
    backgroundColor: '#91e430',
    icon: 'dolly',
  },
  'fix-ready': {
    backgroundColor: '#91e430',
    icon: 'dolly',
  },
  deployready: {
    backgroundColor: '#91e430',
    icon: 'dolly',
  },
  'deploy-ready': {
    backgroundColor: '#91e430',
    icon: 'dolly',
  },
};

export default function TaskTag(props: Props): JSX.Element {
  const tag = props.label;
  const theme = SPECIAL_TAGS[tag.toLowerCase()];
  const style =
    !!theme && !!theme.backgroundColor
      ? { backgroundColor: theme.backgroundColor }
      : {};
  const icon =
    !!theme && !!theme.icon ? (
      <i
        className={`fas fa-${theme.icon}`}
        style={{ marginRight: '0.25rem' }}
      />
    ) : null;

  return (
    <span className="tag" style={style}>
      {icon}
      {tag}
    </span>
  );
}
