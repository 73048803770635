

import React from "react";
import Page from "./_base/Page";
import TaskBacklogCard from "../components/tasks/backlog/TaskBacklogCard";
import TaskQuickCreator from "../components/tasks/create/TaskQuickCreator";

type Props = {
  history: any; // passed by React Router
};

class HomePage extends React.Component<Props, {}> {

  _onTaskSelected = (taskNumber: number): void => {
    this.props.history.push(`/tasks/${taskNumber}`);
  };

  render() {
    return <Page requireLogin={true}>
        <h1 className="page-title">
          Backlog
        </h1>

        <TaskQuickCreator history={this.props.history} />

        <TaskBacklogCard backlogType="personal" onTaskClicked={this._onTaskSelected} />
      </Page>;
  }
}

export default HomePage;