// @source_hash=0ab695a3e66e5915424c57690ad46f0a
// This is an autogenerated file.
// To make changes, edit app/server/endpoints/authentication/UserRegistrationEndpoint.js,
// then run `dragon gen-endpoint app/server/endpoints/authentication/UserRegistrationEndpoint.js`.
// Generated on Fri Apr 10 2020 11:05:42 GMT+0800 (+08)

export interface UserRegistrationEndpointParams {
  inviteCode: string;
  userid: string;
  passwordHash: string;
  emailAddress: string;
  displayName: string;
};

export interface UserRegistrationEndpointResult {
  success: boolean;
  session?: {
	  token: string,
	  displayName: string,
	  uid: string,
	  roles: Array<string>,
	};
  error?: string;
};

export default class UserRegistrationEndpoint {
  static call(params: UserRegistrationEndpointParams): Promise<UserRegistrationEndpointResult> {
    return (global as any).API.call(
      { url: "/api/auth/register", type: "post", params: [] },
      params,
    );
  }
}
