// @source_hash=9acfe8b3c90de649e4bb17310af8e805
// This is an autogenerated file.
// To make changes, edit app/server/endpoints/authentication/RefreshTokenEndpoint.js,
// then run `dragon gen-endpoint app/server/endpoints/authentication/RefreshTokenEndpoint.js`.
// Generated on Fri Apr 10 2020 11:05:42 GMT+0800 (+08)

export interface RefreshTokenEndpointParams {};

export interface RefreshTokenEndpointResult {
  success: boolean;
  session?: {
	  token: string,
	  displayName: string,
	  uid: string,
	  roles: Array<string>,
	};
  error?: string;
};

export default class RefreshTokenEndpoint {
  static call(params: RefreshTokenEndpointParams): Promise<RefreshTokenEndpointResult> {
    return (global as any).API.call(
      { url: "/api/auth/refresh", type: "post", params: [] },
      params,
    );
  }
}
