import React from 'react';
import gql from 'graphql-tag';
import { Query, QueryResult } from 'react-apollo';
import { Link, NavLink } from 'react-router-dom';

import './DashboardListCard.css';

export const GET_DASHBOARDS_QUERY = gql`
  query {
    me {
      owned_dashboards {
        id
        name
        description
      }
    }
  }
`;

export default class DashboardListNavLink extends React.Component<{}, {}> {
  render() {
    return (
      <Query query={GET_DASHBOARDS_QUERY}>
        {(result: QueryResult<any>) => {
          const { loading, error, data } = result;
          if (!!loading || !!error) {
            if (!!error) {
              (global as any).EM.logException(error, {
                component: 'DashboardListNavLink',
                graphQLQuery: 'me.owned_dashboards',
                extraData: {},
              });
            }

            return (
              <NavLink
                to="/boards"
                className="navbar-item"
                activeClassName="active"
              >
                Task Boards
              </NavLink>
            );
          }

          if (data.me == null) {
            // Can't fetch the user, so session isn't valid any more
            (global as any).AuthUtils.clearLoginSession();
          }

          const dashboards = data.me.owned_dashboards;
          if (!dashboards || dashboards.length === 0) {
            return (
              <NavLink
                to="/boards"
                className="navbar-item"
                activeClassName="active"
              >
                Task Boards
              </NavLink>
            );
          }

          return (
            <div className="navbar-item has-dropdown is-hoverable">
              <NavLink
                to="/boards"
                className="navbar-item"
                activeClassName="active"
              >
                Task Boards
              </NavLink>
              <div className="navbar-dropdown">
                {dashboards.map(
                  (dashboard: {
                    id: string | number | undefined;
                    name: JSX.Element;
                  }) => (
                    <Link key={dashboard.id} to={`/boards/${dashboard.id}`}>
                      {dashboard.name}
                    </Link>
                  )
                )}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}
