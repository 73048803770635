// @source_hash=3018dcaca0e931692f7c251c819c3bce
// This is an autogenerated file.
// To make changes, edit app/server/endpoints/authentication/LoginEndpoint.js,
// then run `dragon gen-endpoint app/server/endpoints/authentication/LoginEndpoint.js`.
// Generated on Fri Apr 10 2020 11:05:42 GMT+0800 (+08)

export interface LoginEndpointParams {
  token: string;
  passwordHash: string;
};

export interface LoginEndpointResult {
  success: boolean;
  need_2fa?: boolean | null;
  session?: {
	  token: string,
	  displayName: string,
	  uid: string,
	  roles: Array<string>,
	} | null;
  error?: {
	  code: string,
	  message: string,
	} | null;
};

export default class LoginEndpoint {
  static call(params: LoginEndpointParams): Promise<LoginEndpointResult> {
    return (global as any).API.call(
      { url: "/api/auth/login", type: "post", params: [] },
      params,
    );
  }
}
