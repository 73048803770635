import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import {
  GET_PERSONAL_BACKLOG_QUERY,
  GET_PROJECT_BACKLOG_QUERY,
} from '../backlog/TaskBacklogCard';
import { TaskSummaryFragment } from '../display/TaskSummary';

import './TaskQuickCreator.css';

const QUICK_CREATE_TASK = gql`
  mutation quickCreateTask($title: String!, $tags: String!, $projectID: ID) {
    createTask(
      taskData: {
        title: $title
        description: ""
        tags: $tags
        is_open: true
        priority: normal
        recurrence_type: none
        recurrence_interval_value: null
        recurrence_schedule: null
      }
      projectID: $projectID
    ) {
      ...TaskSummary
    }
  }
  ${TaskSummaryFragment}
`;

type Props = {
  history: any; // from React Router
  onTaskCreated?: (taskID: string, taskNumber: number) => void;
  showMoreDetailsButton: boolean;
  placeholder?: string;
  projectID: string | null | undefined;
  prefillTags: Array<string>;
};

type State = {};

class TaskQuickCreator extends React.Component<Props, State> {
  static defaultProps = {
    showMoreDetailsButton: true,
    projectID: null,
    prefillTags: [],
  };

  titleInput: HTMLInputElement | null | undefined;

  _onSaveCompleted = (data: any): void => {
    const result = data.createTask;
    if (!!this.titleInput) {
      this.titleInput.value = '';
    }
    if (!!this.props.onTaskCreated) {
      this.props.onTaskCreated(result.id, result.task_number);
    }
  };

  _getNewTaskInput = ():
    | {
        title: string;
        tags: string;
        projectID: string | null | undefined;
      }
    | null
    | undefined => {
    const input = !!this.titleInput ? this.titleInput.value.trim() : null;
    if (!input && !this.props.projectID) {
      return null;
    }

    const words = (input || '').split(' ');
    let addedTags = words
      .filter((w) => w.startsWith('#'))
      .map((w) => w.slice(1));
    this.props.prefillTags.forEach((tag) => {
      if (!addedTags.includes(tag)) {
        addedTags.push(tag);
      }
    });
    const tags = addedTags.join(',');
    const title = words
      .filter((w) => !w.startsWith('#'))
      .join(' ')
      .trim();

    return {
      title,
      tags,
      projectID: this.props.projectID,
    };
  };

  _onClickMoreButton = (): void => {
    (window as any).__m_qtc_i = this._getNewTaskInput();
    this.props.history && this.props.history.push(`/tasks/new`);
  };

  render() {
    return (
      <Mutation
        mutation={QUICK_CREATE_TASK}
        update={(
          cache: {
            readQuery: (arg0: {
              query: any;
              variables?: { projectID: string };
            }) => any;
            writeQuery: (arg0: { query: any; data: any }) => void;
          },
          { data }: any
        ) => {
          try {
            const cachedData = cache.readQuery({
              query: GET_PERSONAL_BACKLOG_QUERY,
            });
            const newData = JSON.parse(JSON.stringify(cachedData)); // hacky clone
            newData.me.owned_tasks.push(data.createTask);
            cache.writeQuery({
              query: GET_PERSONAL_BACKLOG_QUERY,
              data: newData,
            });
          } catch (e) {}

          if (!!this.props.projectID) {
            try {
              const cachedProjData = cache.readQuery({
                query: GET_PROJECT_BACKLOG_QUERY,
                variables: {
                  projectID: this.props.projectID,
                },
              });
              const newProjData = JSON.parse(JSON.stringify(cachedProjData)); // hacky clone
              newProjData.project.tasks.push(data.createTask);
              cache.writeQuery({
                query: GET_PROJECT_BACKLOG_QUERY,
                data: newProjData,
              });
            } catch (e) {}
          }
        }}
        onCompleted={this._onSaveCompleted}
      >
        {(
          quickCreateTask: (arg0: {
            variables: {
              title: string;
              tags: string;
              projectID: string | null | undefined;
            };
          }) => void,
          { data }: any
        ) => (
          <div className="task-quick-creator uk-grid-small" data-uk-grid={true}>
            <div
              className={
                this.props.showMoreDetailsButton
                  ? 'uk-width-5-6'
                  : 'uk-width-1-1'
              }
            >
              <input
                className="uk-input"
                ref={(input) => (this.titleInput = input)}
                onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>): void => {
                  if (e.keyCode !== 13) {
                    return;
                  }

                  const inputs = this._getNewTaskInput();
                  if (!inputs) {
                    return;
                  }

                  quickCreateTask({
                    variables: {
                      title: inputs.title,
                      tags: inputs.tags,
                      projectID: inputs.projectID,
                    },
                  });
                }}
                placeholder={
                  this.props.placeholder ||
                  'Type a title and press Enter to create a task. Use #tag for tags.'
                }
              />
            </div>

            {this.props.showMoreDetailsButton && (
              <button
                className="uk-button uk-button-default uk-width-1-6"
                onClick={this._onClickMoreButton}
              >
                More
              </button>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default TaskQuickCreator;
