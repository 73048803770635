import React from 'react';
import classSet from 'react-classset';
import { Link } from 'react-router-dom';
import moment from 'moment';
import TaskTargetDateUtils from '../../../utils/TaskTargetDateUtils';
import TaskTag from '../tags/TaskTag';
import gql from 'graphql-tag';
import { TaskRecurrenceType } from '../../../enums/TaskRecurrence';
import TaskScheduleLabel from './TaskScheduleLabel';
import TaskRecurrenceLabel from '../details/TaskRecurrenceLabel';

import './TaskSummary.css';

export const TaskSummaryFragment = gql`
  fragment TaskSummary on Task {
    id
    is_open
    task_number
    title
    description
    tags
    start_date
    target_date
    created_date
    recurrence_type
    recurrence_schedule
    recurrence_interval_value
    priority
    creator {
      display_name
    }
    prerequisites {
      is_open
    }
    project {
      id
      name
    }
    owner {
      id
      display_name
    }
  }
`;

export type Task = {
  id: string;
  taskNumber: number;
  title: string;
  isOpen: boolean;
  tags: Array<string>;
  startDate: number | null | undefined;
  targetDate: number | null | undefined;
  createdDate: number;
  priority: string;
  creatorName: string;
  prerequisitesStatuses: Array<{ is_open: boolean }>;
  description: string;
  project:
    | {
        id: string;
        name: string;
      }
    | null
    | undefined;
  owner: {
    id: string;
    display_name: string;
  };
  recurrenceType: TaskRecurrenceType;
  recurrenceSchedule: string | null | undefined;
  recurrenceIntervalValue: number | null | undefined;
};

export type Props = {
  task: Task;

  onTaskClicked: (taskNumber: number) => void;
  showDeleteButton?: boolean;
  onClickDeleteButton?: (taskID: string) => void;
};

type State = {};

class TaskSummary extends React.Component<Props, State> {
  _onTaskClicked = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    if (e.ctrlKey || e.metaKey || (window as any).IS_MOBILE) {
      return;
    }

    e.preventDefault();
    this.props.onTaskClicked(this.props.task.taskNumber);
  };

  _onClickDeleteButton = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    if (!!this.props.onClickDeleteButton) {
      this.props.onClickDeleteButton(this.props.task.id);
    }
  };

  render() {
    const props = this.props;
    let priorityTag: JSX.Element | null = null;
    if (props.task.priority === 'high') {
      priorityTag = <span className="tag is-danger no-border">High pri</span>;
    } else if (props.task.priority === 'low') {
      priorityTag = <span className="tag is-info no-border">Low pri</span>;
    }

    const targetDateStatus = TaskTargetDateUtils.getTargetDateStatus(
      props.task.isOpen,
      props.task.targetDate
    );
    const targetDateTag = TaskTargetDateUtils.getTargetDateTag(
      targetDateStatus
    );

    let prerequisitesTag: JSX.Element | null = null;
    if (
      !!props.task.prerequisitesStatuses &&
      props.task.prerequisitesStatuses.length > 0
    ) {
      const openPrerequisites = props.task.prerequisitesStatuses.filter(
        (s) => s.is_open
      ).length;
      prerequisitesTag =
        openPrerequisites > 0 ? (
          <span className="tag no-border prereq-pending">
            Waiting on {openPrerequisites}{' '}
            {openPrerequisites === 1 ? 'task' : 'tasks'}
          </span>
        ) : (
          <span className="tag no-border prereq-done">Prerequisites done</span>
        );
    }

    let projectTag: JSX.Element | null = null;
    if (!!props.task.project) {
      projectTag = (
        <span className="tag no-border project-tag">
          <i className="fas fa-briefcase tag-icon" />
          {props.task.project.name}
        </span>
      );
    }

    return (
      <Link
        className="task-summary-link"
        to={`/tasks/${props.task.taskNumber}`}
        onClick={this._onTaskClicked}
      >
        <div
          className={classSet({
            'closed-task-summary': !props.task.isOpen,
            'deprioritised-task-summary':
              props.task.tags.includes('fixready') ||
              props.task.tags.includes('fix-ready') ||
              props.task.tags.includes('deployready') ||
              props.task.tags.includes('deploy-ready'),
          })}
        >
          {this.props.showDeleteButton ? (
            <div
              className="delete-icon-container"
              data-balloon="Remove this task"
              data-balloon-pos="left"
            >
              <button
                onClick={this._onClickDeleteButton}
                className="uk-button uk-button-default"
              >
                <i className="fas fa-trash-alt" />
              </button>
            </div>
          ) : null}
          <div className="task-summary">
            <h4 className="title">
              <span className="task-number">#{props.task.taskNumber}</span>
              {props.task.title}
            </h4>
            <div className="tags">
              {targetDateTag}
              {priorityTag}
              {prerequisitesTag}
              {projectTag}
              {props.task.tags.map((tag) => (
                <TaskTag key={tag} label={tag} />
              ))}
            </div>
            <div className="details">
              <TaskScheduleLabel
                startDate={props.task.startDate}
                targetDate={props.task.targetDate}
              />{' '}
              {!!props.task.recurrenceType &&
                props.task.recurrenceType !== 'none' && (
                  <>
                    <TaskRecurrenceLabel
                      recurrenceType={props.task.recurrenceType}
                      recurrenceSchedule={props.task.recurrenceSchedule}
                      recurrenceIntervalValue={
                        props.task.recurrenceIntervalValue
                      }
                    />
                    .{' '}
                  </>
                )}
              Created {moment.unix(props.task.createdDate).format('ll')} by{' '}
              {props.task.creatorName}.
              {props.task.project && (
                <span>
                  {' '}
                  Owned by{' '}
                  {!!props.task.owner
                    ? props.task.owner.display_name
                    : 'Unknown'}
                  .
                </span>
              )}
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default TaskSummary;
