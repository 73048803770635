import * as React from 'react';
import moment from 'moment';

export type TaskTargetDateStatus =
  | 'none'
  | 'closed'
  | 'soon'
  | 'overdue'
  | 'ok';

export default {
  getTargetDateStatus(
    isOpen: boolean,
    targetDate: number | null | undefined
  ): TaskTargetDateStatus {
    if (!isOpen) {
      return 'closed';
    }

    if (!targetDate) {
      return 'none';
    }

    const mTargetDate = moment.unix(targetDate);
    if (mTargetDate.isBefore(moment.now())) {
      return 'overdue';
    }

    const daysLeft = moment.duration(mTargetDate.diff(moment.now())).asDays();
    return daysLeft <= 7 ? 'soon' : 'ok';
  },

  getTargetDateTag(
    targetDateStatus: TaskTargetDateStatus
  ): JSX.Element | null | undefined {
    switch (targetDateStatus) {
      case 'closed':
        return <span className="tag task-closed-tag no-border">Done</span>;
      case 'overdue':
        return (
          <span className="tag task-overdue-tag no-border">
            <i className="fas fa-exclamation-triangle tag-icon" />
            Overdue!
          </span>
        );
      case 'soon':
        return (
          <span className="tag task-due-soon-tag no-border">
            <i className="fas fa-hourglass-half tag-icon" />
            Due soon
          </span>
        );
      default:
        return null;
    }
  },

  getTargetDateLabelClass(targetDateStatus: TaskTargetDateStatus): string {
    switch (targetDateStatus) {
      case 'overdue':
        return 'overdue';
      case 'soon':
        return 'due-soon';
      default:
        return '';
    }
  },

  getTaskDetailsCardClass(targetDateStatus: TaskTargetDateStatus): string {
    switch (targetDateStatus) {
      case 'closed':
        return 'closed-task-card';
      case 'overdue':
        return 'overdue-task-card';
      case 'soon':
        return 'due-soon-task-card';
      default:
        return '';
    }
  },
};
