import * as React from 'react';
import classSet from 'react-classset';

import './Notification.css';

type Props = {
  type: 'normal' | 'warning' | 'error';
  canDismiss: boolean;
  children: any;
};

export default function Notification(props: Props): JSX.Element {
  return (
    <div
      className={classSet({
        'notification-container': true,
        'notification-normal': props.type === 'normal',
        'notification-warning': props.type === 'warning',
        'notification-error': props.type === 'error',
      })}
    >
      <div data-uk-alert>
        {props.canDismiss && <a className="uk-alert-close" data-uk-close></a>}

        <div className="not-icon">
          {props.type === 'normal' && <i className="far fa-bell normal"></i>}
          {props.type === 'warning' && (
            <i className="fas fa-exclamation-triangle warning"></i>
          )}
          {props.type === 'error' && (
            <i className="fas fa-exclamation-circle error"></i>
          )}
        </div>
        <div className="contents">{props.children}</div>
      </div>
    </div>
  );
}

Notification.defaultProps = {
  canDismiss: true,
  type: 'normal',
};
