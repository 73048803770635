import React, { Component } from 'react';
import './App.css';
import { Switch, Route, Link } from 'react-router-dom';
import asyncComponent from './core/AsyncComponent';
import Header from './components/header/Header';
import AppVersionWarning from './components/home/AppVersionWarning';

//pages
import HomePage from './pages/Home';
import LoginPage from './components/login/LoginPage';

// Bulma CSS framework
import 'bulma/css/bulma.min.css';

// UIKit
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';
import 'uikit/dist/js/uikit-icons.min.js';

// Balloon.css
import 'balloon-css/balloon.min.css';

// Sentry
import ErrorMonitoring from './utils/ErrorMonitoring';
(global as any).EM = ErrorMonitoring;

const AsyncBacklogKanbanBoardPage = asyncComponent(() =>
  import('./pages/tasks/BacklogKanbanBoardPage')
);

const AsyncNewTaskPage = asyncComponent(() => import('./pages/tasks/NewTask'));
const AsyncTaskDetailPage = asyncComponent(() =>
  import('./pages/tasks/TaskDetail')
);
const AsyncTaskSearchPage = asyncComponent(() =>
  import('./pages/search/TaskSearchPage')
);
const AsyncDashboardHomePage = asyncComponent(() =>
  import('./pages/dashboards/DashboardHome')
);
const AsyncDashboardViewerPage = asyncComponent(() =>
  import('./pages/dashboards/DashboardViewer')
);
const AsyncProjectsHomePage = asyncComponent(() =>
  import('./pages/projects/ProjectsHome')
);
const AsyncProjectsDetailPage = asyncComponent(() =>
  import('./pages/projects/ProjectsDetail')
);
const AsyncProjectsJoinPage = asyncComponent(() =>
  import('./pages/projects/ProjectsJoin')
);
const AsyncProjectStatusReportPage = asyncComponent(() =>
  import('./pages/projects/ProjectStatusCSVReportPage')
);
const AsyncNotFound404Page = asyncComponent(() =>
  import('./pages/NotFound404Page')
);
const AsyncUserAdminPage = asyncComponent(() =>
  import('./pages/profile/UserAdminPage')
);
const AsyncConfirmEmailPage = asyncComponent(() =>
  import('./pages/profile/ConfirmEmailPage')
);

const AsyncAdminHomePage = asyncComponent(() =>
  import('./pages/admin/AdminHome')
);

const AsyncCreateAccountPage = asyncComponent(() =>
  import('./pages/create_account/CreateAccountPage')
);

const AsyncHelpHomePage = asyncComponent(() =>
  import('./pages/help/HelpHomePage')
);

const AsyncPasswordResetPage = asyncComponent(() =>
  import('./pages/profile/PasswordResetPage')
);

const AsyncLoginTwoFacPage = asyncComponent(() =>
  import('./pages/profile/LoginTwoFacPage')
);

class App extends Component {
  componentDidMount() {
    (global as any).AuthUtils.sendHeartbeat();
  }

  render() {
    return (
      <div className="app">
        {(global as any).AuthUtils.isLoggedIn() && <Header />}

        <div className="container">
          <AppVersionWarning />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route
              exact
              path="/kanban"
              component={AsyncBacklogKanbanBoardPage}
            />
            <Route path="/login" component={LoginPage} />
            <Route exact path="/register" component={AsyncCreateAccountPage} />
            <Route exact path="/2fa" component={AsyncLoginTwoFacPage} />

            <Route exact path="/tasks/new" component={AsyncNewTaskPage} />
            <Route exact path="/tasks/search" component={AsyncTaskSearchPage} />
            <Route
              exact
              path="/tasks/:tasknumber"
              component={AsyncTaskDetailPage}
            />
            <Route
              exact
              path="/tasks/:tasknumber/:tab"
              component={AsyncTaskDetailPage}
            />

            <Route exact path="/boards" component={AsyncDashboardHomePage} />
            <Route
              exact
              path="/boards/:boardID"
              component={AsyncDashboardViewerPage}
            />

            <Route exact path="/projects" component={AsyncProjectsHomePage} />
            <Route
              exact
              path="/projects/reports/status"
              component={AsyncProjectStatusReportPage}
            />
            <Route
              exact
              path="/projects/join"
              component={AsyncProjectsJoinPage}
            />
            <Route
              exact
              path="/projects/:projectID"
              component={AsyncProjectsDetailPage}
            />
            <Route
              exact
              path="/projects/:projectID/:tab"
              component={AsyncProjectsDetailPage}
            />
            <Route
              exact
              path="/projects/:projectID/:tab/:subtab"
              component={AsyncProjectsDetailPage}
            />

            <Route exact path="/users/me" component={AsyncUserAdminPage} />
            <Route exact path="/users/me/:tab" component={AsyncUserAdminPage} />
            <Route
              exact
              path="/confirm_email"
              component={AsyncConfirmEmailPage}
            />

            <Route exact path="/help" component={AsyncHelpHomePage} />
            <Route exact path="/help/:path(.*)" component={AsyncHelpHomePage} />

            <Route exact path="/admin" component={AsyncAdminHomePage} />
            <Route exact path="/admin/:tab" component={AsyncAdminHomePage} />

            <Route
              exact
              path="/reset_password"
              component={AsyncPasswordResetPage}
            />

            <Route component={AsyncNotFound404Page} />
          </Switch>
        </div>
        <footer>
          <div>
            <Link to="/help">
              <i className="fas fa-life-ring" /> Help
            </Link>
            |<Link to="/help/privacy-policy">Privacy Policy</Link>
          </div>
          <strong>Mimir</strong> v{(global as any).app_info.version}{' '}
          {(global as any).app_info.build}
          <br />
          &copy; 2015-{new Date().getFullYear()}
          {(global as any).AuthUtils.currentUserHasRole(
            (global as any).AuthUtils.Roles.ADMIN
          ) && (
            <>
              <br />
              <Link to="/admin">
                <i
                  className="fas fa-database"
                  style={{ marginRight: '0.25rem' }}
                />
                Admin
              </Link>
            </>
          )}
        </footer>
      </div>
    );
  }
}

export default App;
