

import { Task as TaskSummary } from "./TaskSummary";

export type SortType = "created_date" | "target_date" | "priority" | "urgency";

export default {
  sort(tasks: Array<TaskSummary>, type: SortType): Array<TaskSummary> {
    switch (type) {
      case 'created_date':
        return this._sortByCreatedDate(tasks);
      case 'target_date':
        return this._sortByTargetDate(tasks);
      case 'priority':
        return this._sortByPriority(tasks);
      case 'urgency':
        return this._sortByUrgency(tasks);
      default:
        console.log('WARNING: Task sort not implemented for this type: ' + type);
        return tasks;

    }
  },

  _sortByCreatedDate(tasks: Array<TaskSummary>): Array<TaskSummary> {
    return tasks.slice().sort((a, b) => b.createdDate - a.createdDate);
  },

  _sortByTargetDate(tasks: Array<TaskSummary>): Array<TaskSummary> {
    // sort tasks with target dates by their target dates
    const sorted = tasks.filter(task => !!task.targetDate).sort((a, b) => (a.targetDate || 0) - (b.targetDate || 0));

    // add tasks with no target date, sorted by their creation date
    return sorted.concat(tasks.filter(task => !task.targetDate).sort((a, b) => a.createdDate - b.createdDate));
  },

  _sortByPriority(tasks: Array<TaskSummary>): Array<TaskSummary> {
    const priorityValues = {
      'low': -1,
      'normal': 0,
      'high': 1
    };
    return tasks.slice().sort((a, b) => priorityValues[b.priority] - priorityValues[a.priority]);
  },

  _sortByUrgency(tasks: Array<TaskSummary>): Array<TaskSummary> {
    const currentDate: number = Math.round(new Date().getTime() / 1000);
    const NO_DEADLINE_BUFFER = 1209600; // 2 weeks
    const priorityMultipliers = {
      'low': 2,
      'normal': 1,
      'high': 0.1
    };

    const taskUrgencyScores = {};
    tasks.forEach(task => {
      const priorityMultiplier = priorityMultipliers[task.priority];
      taskUrgencyScores[task.id] = !!task.targetDate ? (task.targetDate - currentDate) / 3600 * priorityMultiplier : (currentDate - task.createdDate) / 3600 * priorityMultiplier * 10 * NO_DEADLINE_BUFFER;
    });

    return tasks.filter(task => task.isOpen).sort((a, b) => taskUrgencyScores[a.id] - taskUrgencyScores[b.id]).concat(tasks.filter(task => !task.isOpen).slice().sort((a, b) => taskUrgencyScores[a.id] - taskUrgencyScores[b.id]));
  }
};