import React from 'react';

import './Cards.css';

import InfoIcon from './icons/information.svg';
import SuccessIcon from './icons/checked.svg';
import ErrorIcon from './icons/error.svg';
import WarningIcon from './icons/warning.svg';

export function Card(props) {
  return (
    <div className={`content-card ${props.className}`} style={props.style}>
      {props.children}
    </div>
  );
}

export function CardSection(props) {
  return (
    <div className={`card-section ${props.className}`} style={props.style}>
      {props.children}
    </div>
  );
}

function ThemedIconCard(props) {
  return (
    <div className={`content-card ${props.cardTypeClass}`}>
      <img className="icon" src={props.iconSource} alt="Notice card" />
      <div className="body">{props.children}</div>
    </div>
  );
}

export function InfoCard(props) {
  return (
    <ThemedIconCard cardTypeClass="info" iconSource={InfoIcon}>
      {props.children}
    </ThemedIconCard>
  );
}

export function SuccessCard(props) {
  return (
    <ThemedIconCard cardTypeClass="success" iconSource={SuccessIcon}>
      {props.children}
    </ThemedIconCard>
  );
}

export function WarningCard(props) {
  return (
    <ThemedIconCard cardTypeClass="warning" iconSource={WarningIcon}>
      {props.children}
    </ThemedIconCard>
  );
}

/**
 * Available props:
 *   - showErrorReporting : whether to show an option to provide more details to Sentry
 *   - internalErrorMessage : an internal error code/message that will be logged to Sentry
 *   - exception : an Error object
 */
export function ErrorCard(props) {
  return (
    <ThemedIconCard cardTypeClass="error" iconSource={ErrorIcon}>
      <CardSection>{props.children}</CardSection>
      {props.showErrorReporting && (
        <CardSection>
          <button
            className="uk-button uk-button-link"
            onClick={() => {
              const loggerFn = !!props.exception
                ? (global as any).EM.logExceptionAsync(props.exception)
                : (global as any).EM.logMessageAsync(
                    props.internalErrorMessage || 'Unknown error'
                  );
              loggerFn.then((eventID) => {
                (global as any).EM.showReportDialog(eventID);
              });
            }}
          >
            Report this problem
          </button>
        </CardSection>
      )}
    </ThemedIconCard>
  );
}
