import React from 'react';
import AppInfoEndpoint from '../../endpoints/appInfoEndpoint';
import Notification from '../common/Notification';

type Props = {};

type State = {
  shouldShowBetaWarning: boolean;
  shouldShowUnsupportedVersionWarning: boolean;
  shouldShowOldVersionWarning: boolean;
  latestVersion: string | null | undefined;
};

export default class AppVersionWarning extends React.Component<Props, State> {
  state = {
    shouldShowBetaWarning: false,
    shouldShowUnsupportedVersionWarning: false,
    shouldShowOldVersionWarning: false,
    latestVersion: null,
  };

  componentDidMount() {
    AppInfoEndpoint.call({}).then((info) => {
      (global as any).__env = info.environment;
      const isUnsupportedVersion = this._isOldVersion(
        (global as any).app_info.version,
        info.min_client
      );
      let isOldVersion = false;
      if (!isUnsupportedVersion) {
        isOldVersion = this._isOldVersion(
          (global as any).app_info.version,
          info.latest_client
        );
      }
      this.setState({
        shouldShowUnsupportedVersionWarning: isUnsupportedVersion,
        shouldShowOldVersionWarning: isOldVersion,
        shouldShowBetaWarning: info.environment === 'test',
        latestVersion: info.latest_client,
      });
    });
  }

  _isOldVersion = (
    clientVersion: string | null | undefined,
    minVersion: string | null | undefined
  ): boolean => {
    if (!clientVersion) {
      return false;
    }
    if (!minVersion) {
      return false;
    }

    try {
      const clientVersionSegments = clientVersion.split('.');
      const minVersionSegments = minVersion.split('.');
      for (
        let i = 0;
        i < Math.min(clientVersionSegments.length, minVersionSegments.length);
        i++
      ) {
        if (
          parseInt(clientVersionSegments[i]) < parseInt(minVersionSegments[i])
        ) {
          return true;
        }
      }
    } catch (e) {
      console.warn(e);
    }

    return false;
  };

  render() {
    return (
      <>
        {this.state.shouldShowBetaWarning && (
          <Notification type="warning">
            This is a beta instance used for testing. Any changes made here WILL
            be lost.
          </Notification>
        )}
        {this.state.shouldShowUnsupportedVersionWarning && (
          <Notification type="error">
            You're using an older, unsupported version of Mimir.
            {this.state.latestVersion && (
              <> The latest version available is v{this.state.latestVersion}.</>
            )}
            <br />
            <button
              className="uk-button uk-button-primary"
              onClick={() => {
                window.location.reload(true);
              }}
            >
              Reload to latest version
            </button>
          </Notification>
        )}
        {this.state.shouldShowOldVersionWarning && (
          <Notification type="normal">
            You're using an older version of Mimir.
            {this.state.latestVersion && (
              <> The latest version available is v{this.state.latestVersion}.</>
            )}
            <br />
            <button
              className="uk-button uk-button-primary"
              onClick={() => {
                window.location.reload(true);
              }}
            >
              Reload to latest version
            </button>
          </Notification>
        )}
      </>
    );
  }
}
