import React from 'react';
import { Task } from './TaskSummary';
import TaskSummary from './TaskSummary';
import TaskSummaryListSorter from './TaskSummaryListSorter';
import { SortType } from './TaskSummaryListSorter';
import TagHistoryCache from '../../../utils/TagHistoryCache';

import './TaskSummaryList.css';

type Props = {
  tasks: Array<Task>;
  onTaskClicked: (taskNumber: number) => void;
  showDeleteButton?: boolean;
  onClickDeleteButton?: (taskID: string) => void;
  shouldPaginate?: boolean;
  showClosedTasks?: boolean;
  shouldSortTasks?: boolean;
  sortType?: SortType;
};

type State = {
  showLimit: number;
};

const PAGE_SIZE: number = 25;

class TaskSummaryList extends React.Component<Props, State> {
  state = {
    showLimit: PAGE_SIZE,
  };

  _onClickShowMore = (): void => {
    this.setState((prevState) => ({
      showLimit: prevState.showLimit + PAGE_SIZE,
    }));
  };

  render() {
    let tasks = this.props.tasks.slice();
    let showMoreLink: JSX.Element | null = null;

    if (!this.props.showClosedTasks) {
      tasks = tasks.filter((task) => task.isOpen);
    }
    const totalVisibleCount = tasks.length;

    if (this.props.shouldSortTasks) {
      tasks = TaskSummaryListSorter.sort(
        tasks,
        this.props.sortType || 'urgency'
      );
    }

    if (this.props.shouldPaginate) {
      tasks = tasks.slice(0, this.state.showLimit);

      if (totalVisibleCount > tasks.length) {
        showMoreLink = (
          <div className="show-more-section">
            <button
              className="uk-button uk-button-link"
              onClick={this._onClickShowMore}
            >
              Show {Math.min(totalVisibleCount - tasks.length, PAGE_SIZE)} more
            </button>
          </div>
        );
      }
    }

    if (TagHistoryCache.getTags().length === 0 && tasks.length > 0) {
      let allTags = tasks
        .map((task) => task.tags.filter((tag) => !!tag))
        .reduce((t1, t2) => t1.concat(t2));
      allTags = allTags.filter((tag, i) => allTags.indexOf(tag) === i);
      TagHistoryCache.initialiseTagsCache(allTags);
    }

    return (
      <div className="task-list">
        {tasks.map((task) => (
          <TaskSummary
            key={task.taskNumber}
            onTaskClicked={this.props.onTaskClicked}
            showDeleteButton={this.props.showDeleteButton}
            onClickDeleteButton={this.props.onClickDeleteButton}
            task={task}
          />
        ))}
        {showMoreLink}
      </div>
    );
  }
}

export default TaskSummaryList;
