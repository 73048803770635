

import React from "react";

import "./Spinner.css";

export type SpinnerDisplayMode = "block" | "inline";

type Props = {
  display: SpinnerDisplayMode;
};

class Spinner extends React.Component<Props> {

  static defaultProps = {
    display: 'block'
  };

  render() {
    return <div className={`spinner ${this.props.display}`} />;
  }
}

export default Spinner;