// @ts-nocheck

import React from 'react';
import './LoginPage.css';

import { Card, ErrorCard } from '../common/cards';
import LoginForm from './LoginForm';

import logo from '../../mimir2_logo_full.png';

class LoginPage extends React.Component {
  render() {
    return (
      <div className="page-content">
        <img className="logo-main" alt="Mimir" src={logo} />

        {window.location.search === '?error=role' && (
          <ErrorCard>
            <h3>No access to this page</h3>
            Your account doesn't seem to have access to this page. If you have
            another account, try switching to that account.
          </ErrorCard>
        )}

        <Card>
          <div>To continue, you'll need to log in.</div>

          <LoginForm />
        </Card>
      </div>
    );
  }
}

export default LoginPage;
