// @source_hash=8105a001f0d78f89c7c7afd582824bba
// This is an autogenerated file.
// To make changes, edit app/server/endpoints/authentication/2fa/Confirm2FAVerificationCodeEndpoint.js,
// then run `dragon gen-endpoint app/server/endpoints/authentication/2fa/Confirm2FAVerificationCodeEndpoint.js`.
// Generated on Fri Apr 10 2020 11:05:42 GMT+0800 (+08)

export interface Confirm2FAVerificationCodeEndpointParams {
  uid: string;
  code: string;
};

export interface Confirm2FAVerificationCodeEndpointResult {
  success: boolean;
  session?: {
	  token: string,
	  displayName: string,
	  uid: string,
	  roles: Array<string>,
	} | null;
};

export default class Confirm2FAVerificationCodeEndpoint {
  static call(params: Confirm2FAVerificationCodeEndpointParams): Promise<Confirm2FAVerificationCodeEndpointResult> {
    return (global as any).API.call(
      { url: "/api/auth/login/2fa_verify", type: "get", params: [] },
      params,
    );
  }
}
