import React from 'react';
import { SortType } from './TaskSummaryListSorter';

import './TaskSortTypeSelector.css';

type Props = {
  onChange: (selectedSortType: SortType) => void;
  selectedSortType: SortType;
};

const SORT_TYPE_LABELS: { [key in SortType]: string } = {
  created_date: 'Created date',
  target_date: 'Deadline',
  priority: 'Priority',
  urgency: 'Both deadline and priority',
};

class TaskSortTypeSelector extends React.Component<Props, {}> {
  _onSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.onChange((e.currentTarget.value as SortType) || 'urgency');
  };

  render() {
    return (
      <div className="uk-grid-small uk-child-width-auto uk-grid sort-type-selector">
        Sort tasks by
        {Object.keys(SORT_TYPE_LABELS).map((sort_type) => (
          <label key={sort_type}>
            <input
              className="uk-radio"
              type="radio"
              name="radio2"
              checked={this.props.selectedSortType === sort_type}
              onChange={this._onSelect}
              value={sort_type}
            />{' '}
            {SORT_TYPE_LABELS[sort_type]}
          </label>
        ))}
      </div>
    );
  }
}

export default TaskSortTypeSelector;
